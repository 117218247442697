<template>
    <div class="ContactUs" style="text-align:center;padding-top:20px">
        <h1>Contatti</h1>
        <p>Grazie per aver visitato mashiguang.it! </p>
        <p>Hai bisogno d'aiuto? Siamo qui per te!</p>
        <p>Per il Servizio Clienti, contattaci su info@manshiguang.it</p>
    </div>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';

export default {
    name: 'ContactUs',
    data() {
        return {
            num1: 0,
            newList: []
        };
    },
    created() {
        this.$emit('update:layout', LayoutDefault);
    },
};
</script>

<style scoped>
div{
    line-height: 40px;
}
</style>