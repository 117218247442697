<template>
    <div class="About" style="text-align:center">
        <h1>Ingredienti Disclaimer</h1>
        <h1>Disclaimer</h1>
        <p>Tutte le informazioni sul prodotto (inclusi ingredienti e foto del prodotto) elencate sul sito Web potrebbero
            non essere aggiornate.</p>
        <p>I produttori possono modificare gli ingredienti o le pratiche di produzione a nostra insaputa.</p>
        <p>Leggere sempre l'etichetta degli ingredienti sulla confezione effettiva prima di consumare per le
            informazioni più aggiornate.</p>
        <p>In caso di allergie o allergie alimentari gravi, contattare sempre direttamente il produttore per confermare
            la sicurezza del prodotto per la propria situazione.</p>
        <p>Se vedete qualche errore nella descrizione o nella lista dei ingredienti, vi preghiamo di contatarci e
            farcelo notare.</p>

    </div>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';

export default {
    name: 'About',
    data() {
        return {
            num1: 0,
            newList: []
        };
    },
    created() {
        this.$emit('update:layout', LayoutDefault);
    },
};
</script>
<style scoped>
div{
    line-height: 40px;
}
</style>
