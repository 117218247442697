<template>
    <el-card class="goods-card">
        <el-badge :value="goods.discountTip" :hidden="goods.discount == 0">
            <div style="text-align: center;">
                <router-link :to="{ path: '/goods', query: { spuId: goods.spuId } }">

                    <el-image :src="goods.cover" fit="fill" class="goods-cover" />

                </router-link>
                <router-link class="product-name" :to="{ path: '/goods', query: { spuId: goods.spuId } }">{{
                        goods.spuName
                }}
                </router-link>
                <p class="product-price">€{{ goods.price }}</p>
                <p class="product-discount">€{{ goods.originPrice }}</p>
                <p>
                    <Stepper :data="data" size="large" />
                </p>
            </div>
        </el-badge>
    </el-card>
</template>

<script>
import Stepper from '@/components/Steppers.vue';

export default {
    name: "goods",
    props: ["data"],
    data() {
        return {
            goods: {}
        }
    },
    mounted() {
        const thisGoods = JSON.parse(JSON.stringify(this.data));
        thisGoods.discountTip = "";
        if (thisGoods.discount > 0)
            thisGoods.discountTip = "-" + parseInt(thisGoods.discount) + "%";
        this.goods = thisGoods;

        console.log("---->",this.goods);
    },
    methods: {}, components: { Stepper }
}</script>
<style scoped>
.goods-cover {
    width: 210px;
    height: 210px;
    border-radius: 5px;
}

.goods-card {
    width: 250px;
}

.el-badge__content.is-fixed {
    right: 30px !important;
}
</style>