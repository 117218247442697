import { createWebHashHistory, createRouter } from "vue-router";

import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import List from '@/views/List.vue';
import Category from '@/views/Category.vue';
import My from '@/views/My.vue';
import Goods from '@/views/Goods.vue';
import Address from '@/views/Address.vue';
import Cart from '@/views/Cart.vue';
import ContactUs from '@/views/ContactUs.vue';
import Privacy from '@/views/Privacy.vue';
import Disclaimer from '@/views/Disclaimer.vue';
import Destroy from '@/views/Destroy.vue';

const routes = [{
        path: '/',
        component: Home,
    },
    {
        path: '/about',
        component: About,
    },
    {
        path: '/list',
        component: List
    },
    {
        path: '/category',
        component: Category
    },
    {
        path: '/my',
        component: My
    },
    {
        path: '/goods',
        component: Goods
    },
    {
        path: '/address',
        component: Address
    },
    {
        path: '/cart',
        component: Cart
    },
    {
        path: '/contactus',
        component: ContactUs
    },
    {
        path: '/privacy',
        component: Privacy
    }, {
        path: '/disclaimer',
        component: Disclaimer
    }, {
        path: '/destroy',
        component: Destroy
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
export default router;