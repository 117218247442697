<template>
  <div>
    <el-row style="padding:10px">
      <el-col class="center">
        <h1>Offerte</h1>
      </el-col>
    </el-row>
    <el-row style="padding-bottom:20px">
      <el-col class="center">
        <el-tag class="ml-2" type="danger" size="large">Scopri di più</el-tag>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="2"></el-col>
      <el-col class="center" :span="20">
        <GoodsCarousel :data="newList"></GoodsCarousel>
      </el-col>
    </el-row>

    <el-row style="padding:10px">
      <el-col class="center">
        <h1>Nuovi prodotti</h1>
      </el-col>
    </el-row>
    <el-row style="padding-bottom:20px">
      <el-col class="center">
        <el-tag class="ml-2" type="danger" size="large">Scopri di più</el-tag>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="2"></el-col>
      <el-col class="center" :span="20">
        <GoodsCarousel :data="recommendList"></GoodsCarousel>
      </el-col>
    </el-row>
    <el-row style="padding:10px">
      <el-col class="center">
        <h1>Best sellers</h1>
      </el-col>
    </el-row>
    <el-row style="padding-bottom:20px">
      <el-col class="center">
        <el-tag class="ml-2" type="danger" size="large">Scopri di più</el-tag>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="2"></el-col>
      <el-col class="center" :span="20">
        <GoodsCarousel :data="discountList"></GoodsCarousel>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import GoodsCarousel from '@/components/GoodsCarousel.vue';
export default {
  name: 'Home',
  data() {
    return {
      newList: [],
      recommendList: [],
      discountList: []
    };
  },
  async created() {
    // await this.getList(1);
    // await this.getList(2);
    // await this.getList(3);
    this.$emit('update:layout', LayoutDefault);
     console.log("Home----created>", this.newList);
  }, methods: {
    async getList(type) {
      await this.$httpClient({
        url: "/api/goods/goodsList",
        data: {
          shop_id: 4,
          type: type,
          limit: 12
        }
      }, (data) => {
        console.log("Home----data>", data);
        data.list.forEach(n => {
          switch (type) {
            case 1:
              this.newList.push(n);
              break;
            case 2:
              this.recommendList.push(n);
              break;
            case 3:
              this.discountList.push(n);
              break;
          }
        });
      }, (res) => {

      }, true);

    }
  },
  async mounted() {

  }, components: {
    GoodsCarousel
  }
};
//https://segmentfault.com/q/1010000014262075
</script>
<style scoped>
.box-card {
  width: 20%;
  float: left;
}
</style>