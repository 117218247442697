<template>
<el-row>
    <el-col class="center" style="padding:20px"><el-tag type="danger" size="large">Scopri di più</el-tag></el-col>
</el-row>
    <el-row style="margin-top:20px" v-infinite-scroll="pageLoad" >
        <el-col :span="2"></el-col>
        <el-col class="center" :span="20">
            <el-space wrap>
                <GoodsView :data="item" v-for="(item, idx) in goodsList" :key="idx"></GoodsView>
            </el-space>
        </el-col>
    </el-row>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import GoodsView from '@/components/GoodsView.vue';

export default {
    name: 'List',
    data() {
        return {
            goodsList: [],
            queryData: {
                page: 0,
                shop_id: 4,
                limit: 14
            }
        };
    },
    mounted() {
        this.getList(this.$route.query.type); 
        this.$emit('update:layout', LayoutDefault);

    }, watch: {
        '$route'(to, from) {
            //window.location.href="http://localhost:8080/#/list?type=2";
            this.queryData.page=0;
            this.getList(to.query.type)
        },
        immediate: true
    }, methods: {
        getList(type) {
            if(this.queryData.page==0)
                this.goodsList=[];
            this.queryData.page += 1;
            this.queryData.type = type;
            this.$httpClient({
                url: "/api/goods/goodsList",
                data: this.queryData
            }, (data) => {
                data.list.forEach((item) => {
                    this.goodsList.push(item);
                });

            }, (res) => { }, true);
        }, pageLoad() {
            this.getList(this.$route.query.type);
        }
    },
    components: {
        GoodsView
    }
};
</script>
