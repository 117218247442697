<template>
    <el-carousel height="370px" :autoplay="false" :loop="true" arrow="always">
        <el-carousel-item>
            <el-space wrap>
                <GoodsView :data="g" v-for="(g, idx) in goodsList" :key="idx"></GoodsView>
            </el-space>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
import GoodsView from '@/components/GoodsView.vue';

export default {
    name: "GoodsCarousel",
    props: ["data"],
    data() {
        return {
            list: [],
            goodsList:[]
        }
    },
    async mounted() {

        // var goodsList = this.$toRaw(this.data);
        // console.log("GoodsCarousel----goodsList>", goodsList);
        await this.getList(1);

    },
    methods: {
        fill(goodsList) {
            if (this.list.length > 0)
                return;
            for (var i = 0; i < goodsList.length; i++) {
                if (i % 6 == 0) {
                    this.list.push([]);
                }
                var len = this.list.length;
                goodsList[i].num = 0;
                this.list[len - 1].push(goodsList[i]);
            }
        }, async getList(type) {
            await this.$httpClient({
                url: "/api/goods/goodsList",
                data: {
                    shop_id: 4,
                    type: type,
                    limit: 6
                }
            }, (data) => {
                console.log("GoodsCarousel----data>", data);

this.goodsList=data.list;
                //this.fill(data.list);

                // data.list.forEach(n => {
                //     switch (type) {
                //         case 1:
                //             this.newList.push(n);
                //             break;
                //         case 2:
                //             this.recommendList.push(n);
                //             break;
                //         case 3:
                //             this.discountList.push(n);
                //             break;
                //     }
                // });
            }, (res) => {

            }, true);

        }
    }
    ,
    components: {
        GoodsView
    }
}</script>