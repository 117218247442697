import { createApp } from 'vue'
import { toRaw } from '@vue/reactivity'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Qs from 'qs'
import { ElMessage } from 'element-plus'
//import '@/assets/style.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import md5 from "@/assets/md5.js";
const app = createApp(App);
app.use(router);
app.use(ElementPlus);
router.isReady().then(() => {
    app.mount('#app')
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.config.globalProperties.$httpClient = httpClient;
app.config.globalProperties.$categoies = [];
app.config.globalProperties.$toRaw = toRaw;
app.config.globalProperties.$setData = setData;
app.config.globalProperties.$getData = getData;
app.config.globalProperties.$showMsg = (text, type) => {
    if (type == null || type == undefined || type == '')
        type = 'success';
    ElMessage({
        message: text,
        type: type,
    });
};


//axios.defaults.baseURL = 'https://dev-admin.manshiguang.it/';
axios.defaults.baseURL = 'https://admin.manshiguang.it/';
//axios.defaults.headers.common['MSG-ORIGIN'] = "pc";
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['MSG-LANGUAGE'] = "it";
async function httpClient(postData, successFunc, errorFunc, cached) {
    cached = false;
    if (cached == undefined || postData.data == null || postData.data == undefined)
        cached = false;
    if (postData.data == null || postData.data == undefined)
        postData.data = {};
    sign(postData.data);
    console.log(postData);
    if (postData.method == "" || postData.method == undefined)
        postData.method = "POST";

    postData.data = Qs.stringify(postData.data);

    await axios(postData).then(response => {
        console.log(response);
        if (response.data.status == "SUCCESSS") {
            if (successFunc != undefined) {
                successFunc(response.data.data);
            }
            return;
        }
        if (response.data.status == "OK") {
            if (successFunc != undefined) {
                successFunc(response.data);
            }
            return;
        }

        let errMsg = (response.data.errMsg == undefined) ? response.data.msg : response.data.errMsg;
        if (errorFunc == undefined || errorFunc == null) {
            if (response.data.code == 2003) {
                //go('../home/login');
            }
            //if (errMsg.indexOf("abort") < 0)
            //toast(errMsg);
        } else {
            errorFunc(response);
        }
    }).catch((exception, b) => {
        console.error("接口请求出错:", exception);
        console.error("接口请求出错:", postData);
        //if (exception.errMsg != undefined && exception.errMsg != null && exception.errMsg.indexOf("abort") <0)
        //toast("未知错误:" + exception.errMsg);
    });
}


function sign(postData) {
    if (postData == null || postData == undefined)
        postData = {};

    let result;
    var keysArr = Object.keys(postData).sort();
    var sortObj = '';
    for (var i = 0; i < keysArr.length; i++) {
        var key = keysArr[i];
        if (key != 'tk' && key != 'ts') {
            sortObj += key + '=' + postData[key];
            if (i < keysArr.length - 1)
                sortObj += '&';
        }
    }
    if (sortObj == undefined)
        sortObj = '';
    postData.time = Math.floor(Date.now() / 1000);
    result = sortObj.toString() + getData("secretKey") + postData.time;
    postData.sign = md5.hex_md5(result);
    postData.token = getData("token");
}

function getData(key) {
    let result;
    //setData("token", "bf51810f4b3e9b55b7ed9e852483eab4");
    result = localStorage.getItem(key);
    return result;
}

function setData(key, data) {
    localStorage.setItem(key, data);
}

function getLanague() {
    var result = getData("lang");
    if (result == "" || result == undefined || result == null)
        result = "cn";
    return result;
}