<template>
    <el-row style="margin-top:20px">
        <el-col :span="4"></el-col>
        <el-col :span="16">
            <el-breadcrumb :separator-icon="ArrowRight">
                <el-breadcrumb-item :to="{ path: '/' }">home</el-breadcrumb-item>
                <el-breadcrumb-item>indirizzi</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="4"></el-col>
    </el-row>
    <el-row style="margin-top:20px">
        <el-col :span="4"></el-col>
        <el-col :span="16">
            <el-space wrap>
                <el-card class="box-card" v-for="(item, index) in addresses" :key="index">
                    <template #header>
                        <div class="card-header">
                            <span class="address-name">{{ item.man }}</span>
                            <el-tag class="ml-2 set-default" type="danger" v-if="item.defaultFlag == '1'"
                                style="margin-top:8px">default</el-tag>
                            <el-button class="button set-default" text @click="editAddress(item)">
                                <el-icon>
                                    <Edit></Edit>
                                </el-icon>
                            </el-button>
                        </div>
                    </template>
                    <div>{{ item.phone }}</div>
                    <div>{{ item.area }}</div>
                </el-card>
                <el-card class="box-card">
                    <el-icon style="width:120px;height:120px;cursor: pointer;" @click="editAddress(address)">
                        <Plus style="width:50px;height:50px;color:#eee;" />
                    </el-icon>
                </el-card>
            </el-space>
        </el-col>
        <el-col :span="4"></el-col>
    </el-row>
    <el-drawer v-model="showAddress" title="Indirizzo Modifica">
        <div>
            <el-form ref="form" :model="address" label-width="auto" :label-position="'top'" :size="size"
                v-loading="loading">
                <el-form-item label="destinatario*" prop="man">
                    <el-input v-model="address.man" />
                </el-form-item>
                <el-form-item label="Telefono *">
                    <el-input v-model="address.phone" />
                </el-form-item>
                <el-form-item label="nazione *">
                    <el-select v-model="address.country" @change="countryChange">
                        <el-option :label="item.name" :value="item.name" v-for="(item, index) in areas" :key="index" />
                    </el-select>
                </el-form-item>
                <el-form-item label="Provincia *" v-if="false">
                    <el-input v-model="address.province" />
                </el-form-item>
                <el-form-item label="Città *" v-if="false">
                    <el-autocomplete v-model="address.city" :fetch-suggestions="querySearchCity"
                        placeholder="Immettere qualsiasi carattere per la ricerca" placement="bottom-start" clearable class="inline-input"
                        :trigger-on-focus="false" style="width:100%" @select="selectCity" />
                </el-form-item>
                <el-form-item label="Città*" v-if="address.country != '意大利（ITALIA）'">
                    <el-input v-model="address.city" />
                </el-form-item>
                <el-form-item label="indirizzo*">
                    <el-autocomplete v-model="address.address" :fetch-suggestions="addressAutocomplete"
                        :clearable="true" :trigger-on-focus="false" prefix-icon="MapLocation" style="width:100%"
                        placeholder="Please input" @select="selectAddress">
                        <template #default="{ item }">
                            <div>
                                <el-icon>
                                    <Location />
                                </el-icon>{{ item.value }}
                            </div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item label="codice postale *" v-if="address.country != '意大利（ITALIA）'">
                    <el-input v-model="address.postcode" />
                </el-form-item>
                <el-form-item label="E-mail">
                    <el-input v-model="address.email" />
                </el-form-item>
                <el-form-item label="Nota">
                    <el-input v-model="address.remark" type="textarea" autosize />
                </el-form-item>
                <el-form-item label="predefinito">
                    <el-switch v-model="address.default" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">Salva</el-button>
                    <el-button @click="this.showAddress = false">Annulla</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-drawer>
</template>
<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import { Loader } from '@googlemaps/js-api-loader';
import http from 'axios'
const loader = new Loader({
    apiKey: "AIzaSyB5J2VVbWrtw9BZX-cKZtf1jsG90CSuXjc",
    version: "weekly",
    libraries: ["places"]
});
//AIzaSyB5J2VVbWrtw9BZX-cKZtf1jsG90CSuXjc
export default {
    name: 'Address',
    data() {
        return {
            addresses: [],
            showAddress: false,
            loading: false,
            countryCode: "IT",
            address: {
                "man": "",
                "phone": "",
                "areaId": "",
                "areaJson": ["", "", ""], //国家，省，市
                "province": "",
                "city": "",
                "country": "",
                "address": "",
                "postcode": "",
                "defaultFlag": "",
                "wechat": "?",
                "email": "",
                "remark": "",
                "shopName": "",
                "isInvoice": "",
                "invoiceType": "",
                "invoiceName": "",
                "invoicePiva": "",
                "invoiceCodiceFiscale": null,
                "invoiceSdi": "",
                "invoiceJson": ["", "", ""],
                "invoicePostcode": "",
                "invoiceAddress": "",
                "isInvoiceSame": "",
                "countryId": "",
                "region": null,
                "houseNumber": "snc/无门牌",
                "default": false
            }, areas: []
        };
    },
    created() {
        this.$emit('update:layout', LayoutDefault);
        this.getAllAddress();


    }, methods: {
        getAllAddress() {

            this.$httpClient({
                url: "api/address/listNew",
                data: {}
            }, (data) => {
                data.forEach(item => {
                    if (item.areaJson != undefined && item.areaJson != null) {
                        item.area = item.areaJson.join();
                    }
                });
                this.addresses = data;
            }, (res) => { }, true);
        },
        setDefault() {
            this.$showMsg('esecuzione riuscita');
        }, editAddress(item) {
            if (item != null) {
                this.address = JSON.parse(JSON.stringify(item));
                this.address.country = item.areaJson[0];
                this.address.city = item.postcode + '-' + item.areaJson[2] + "-" + item.areaJson[1];
                this.address.province = item.areaJson[1];
                this.address.default = this.address.defaultFlag == "1";
            }
            this.showAddress = true;
            this.address.city = this.address.postcode + '-' + this.address.areaJson[2] + "-" + this.address.areaJson[1];
            this.$httpClient({
                url: "api/area/list",
                data: {}
            }, (data) => {
                this.areas = data;
            }, (res) => {
            }, true);
        }
        , querySearchCity(queryString, cb) {
            if (queryString == undefined || queryString == null || queryString.length <= 1) {
                cb([]);
                return;
            }
            this.$httpClient({
                url: "/api/area/citySearch",
                data: {
                    key: queryString,
                    area_id: 4173,
                },
            },
                (response) => {
                    var results = response.map(n => {
                        n.value = n.postcode + '-' + n.name + '-' + n.province;
                        return n;
                    });
                    cb(results);
                }
            );
        }, selectCity(e) {
            this.address.province = e.province;
            this.address.areaId = e.areaId;
            this.address.postcode = e.postcode;
            this.address.city = e.postcode + '-' + e.name + '-' + e.province;

        }, selectAddress(e) {
            var province = e.obj.terms[1];
            var city = e.obj.terms[2];
            http({method: 'get',
                url: "https://maps.googleapis.com/maps/api/geocode/json?place_id=" + e.obj.place_id + "&key=AIzaSyB5J2VVbWrtw9BZX-cKZtf1jsG90CSuXjc",
                secure: false
            }).then(res => {
                var response=res.data;
                this.address.province = province.value;
                this.address.areaId = e.areaId;
                if (response.results != undefined && response.results != null && response.results.length > 0 && response.results[0].address_components != undefined && response.results[0].address_components != null && response.results[0].address_components.length > 0)
                    this.address.postcode = response.results[0].address_components.find(n => n.types[0] == 'postal_code').long_name;
                this.address.city = this.address.postcode + '-' + city.value + '-' + province.value;
                // var route= response.results[0].address_components.find(n=>n.types.findIndex(s=>s=="route")>=0).long_name;
                // var locality= response.results[0].address_components.find(n=>n.types.findIndex(s=>s=="locality")>=0).long_name;
                // var street_number= response.results[0].address_components.find(n=>n.types.findIndex(s=>s=="street_number")>=0).long_name;
                if (e.obj.terms.length > 2) {
                    this.address.address = "";
                    for (var i = 0; i <= e.obj.terms.length - 3; i++) {
                        this.address.address += e.obj.terms[i].value + " ";
                    }
                }

                console.log(this.address.city);
                console.log(this.address.address);
            }).catch((exception, b) => {
                console.error("接口请求出错:", exception);
            });
        }, onSubmit() {
            var areaArry = [];
            areaArry.push(this.address.country);
            areaArry.push(this.address.province);
            areaArry.push(this.address.city.split('-')[1]);
            this.address.areaJson = JSON.stringify(areaArry);
            this.address.invoiceJson = JSON.stringify(this.address.invoiceJson);
            this.address.defaultFlag = this.address.default ? "1" : "0";
            this.address.areaId = 4206;

            var re = /[^\x00-\xff]/g;
            if (this.address.man == null || this.address.man == '' || this.address.man == undefined) {
                this.$showMsg("请输入收件人", "error");
                return;
            }
            if (re.test(this.address.man)) {
                this.$showMsg("收件人只允许英文,数字以及符号,不能输入中文", "error");
                return;
            }
            if (this.address.province == null || this.address.province == "") {
                this.$showMsg("请输入省份", "error");
                return;
            }
            if (this.address.city == null || this.address.city == "") {
                this.$showMsg("请输入城市", "error");
                return;
            }
            if (this.address.postcode == null || this.address.postcode == "") {
                this.$showMsg("请输入邮编", "error");
                return;
            }
            this.loading = false;


            this.$httpClient({
                url: "/api/address/updateNew",
                data: this.address,
            },
                (response) => {
                    this.loading = false;
                    this.showAddress = false;
                    this.getAllAddress();
                }, (e) => {
                    this.$showMsg(e.data.msg, "error");
                    return;
                }
            );
        }, countryChange(e) {
            this.countryCode = this.areas.find(n => n.name == e).shortName;
            console.log(this.countryCode);
        }, addressAutocomplete(queryString, cb) {

            if (queryString == null || queryString.length <= 1)
                return;
            // this.$httpClient({
            //     url: "https://maps.googleapis.com/maps/api/geocode/json?place_id=ChIJBQzFde_x3BIRUItKn0KYkis&key=AIzaSyB5J2VVbWrtw9BZX-cKZtf1jsG90CSuXjc",
            //     data: {},
            // },
            //     (response) => {
            //         console.log(response);
            //     }
            // );
            loader.load().then((google) => {
                var service = new google.maps.places.AutocompleteService();
                // var place=new  google.maps.places.PlacesService();

                // var placeRequest={placeId:"ChIJBQzFde_x3BIRUItKn0KYkis"};
                // place.getDetails(placeRequest,(a,b)=>{
                //     console.log(a)
                //     console.log(b)
                // })

                var request = {
                    input: queryString,
                    language: this.countryCode,
                    componentRestrictions: { country: this.countryCode },
                };
                service.getPredictions(request, (predictions, status) => {
                    console.log("------->predictions", predictions);
                    console.log("------->status", status);
                    if (predictions == null)
                        return;
                    var results = [];
                    predictions.forEach(add => {
                        results.push({ value: add.description, obj: add });
                    });

                    cb(results);

                });
            })
                .catch(e => {
                    console.log(e)
                });

        }
    }
};
</script>
<style scoped>
.address-name {
    font-weight: 500;
    font-size: 20px;
}

.set-default {
    float: right;
    margin-top: 5px;
}
</style>