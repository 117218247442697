<template>

    <el-row :gutter="20">
        <el-col :md="2" :xl="5"></el-col>
        <el-col :md="11" :xl="8">
            <el-carousel class="goods-carousel">
                <el-carousel-item v-for="(item, index) in goodsDetails.allPics" :key="index" class="goods-carousel">
                    <el-image :src="item + '&w=1600'" class="cover" fill="cover"  style="max-width: 600px;"/>
                </el-carousel-item>
            </el-carousel>
        </el-col>
        <el-col :md="11" :xl="11">
            <dl>
                <dd>
                    <h1>{{ goodsDetails.spuName }}</h1>
                </dd>
                <dd style="height:10px;"><span class="price">{{ goodsDetails.price }}€</span><span class="originPrice">{{
                        goodsDetails.originPrice
                }}</span></dd>
                <dd style="height:10px;padding:0;"><el-divider style="width:80%"></el-divider></dd>
                <dd>SN：{{ goodsDetails.sn }}</dd>
                <dd>Pacchetto：{{ goodsDetails.packNum }}</dd>
                <dd>Qtà：{{ goodsDetails.boxNum }}</dd>
                <dd v-if="goodsDetails.expiredShow == 1 && goodsDetails.expiration != '1970-01-01'">Scadenza：{{ goodsDetails.expiration }}</dd>
                <dd>
                    <el-input-number />
                </dd>
            </dl>
        </el-col>
    </el-row>
    <el-row style="min-height:400px;margin-top: 30px;">
        <el-col :span="3"></el-col>
        <el-col :span="18">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="Descrizione" name="0">
                     <p><h1>{{goodsDetails.spuName}}</h1></p>
                     <p>{{ goodsDetails.content }}</p>
                </el-tab-pane>
                <el-tab-pane label="Ingredienti" name="1">{{ goodsDetails.element.element }}</el-tab-pane>
                <el-tab-pane label="Allergene" name="2">{{ goodsDetails.element.allergen }}</el-tab-pane>
            </el-tabs>
           
        </el-col>
        <el-col :span="3"></el-col>
    </el-row>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';

export default {
    name: 'Goods',
    data() {
        return {
            num1: 0,
            goodsDetails: {
                comments: {
                    total: 0
                }, element: { element: '', allergen: '' }
            }
        };
    },
    created() {
        this.$emit('update:layout', LayoutDefault);
    const spuId=this.$route.query.spuId;
        this.$httpClient({
            url: "/api/goods_spu/get",
            data: {
                spu_id: spuId
            }
        }, (data) => {
            this.goodsDetails = data;
            this.goodsDetails.allPics = [];
            this.goodsDetails.allPics.push(this.goodsDetails.cover);
            this.goodsDetails.pics = this.goodsDetails.pics == null || this.goodsDetails.pics ==
                undefined ? [] : this.goodsDetails.pics;
            for (var pic in this.goodsDetails.pics) {
                this.goodsDetails.allPics.push(this.goodsDetails.pics[pic]);
            }

            var element = [];
            var allergen = [];

            if (data.element != null && data.element != undefined) {
                var es = this.goodsDetails.element.element.split(',');
                var as = this.goodsDetails.element.allergen.split(',');

                for (var i = 0; i < es.length; i++) {
                    if (element.indexOf(es[i]) === -1) {
                        element.push(es[i])
                    }
                }
                for (var i = 0; i < as.length; i++) {
                    if (allergen.indexOf(as[i]) === -1) {
                        allergen.push(as[i])
                    }
                }
                this.goodsDetails.element = { element: es.join(','), allergen: as.join(',') };
            } else {
                this.goodsDetails.element = { element: '', allergen: '' };
            }
        }, (res) => { }, true);


    }, methods: {

    }
};
</script>
<style scoped>
.goods-carousel {
    height: 600px;
    width: 650px;
    padding: 10px;
    float: left;
    overflow: hidden;
}

.goods-tab {
    height: 1200px;
    width: 650px;
    padding: 10px;
    float: left;
}

.price {
    font-size: 20px;
    font-weight: 400;
}

.originPrice {
    font-size: 12px;
    text-decoration: line-through;
    color: #aaa;
    padding-left: 10px;
}

dl {
    margin-top: 30px;
}

dd {
    padding: 5px;
    height: 50px;
    font-weight: 300;
    color:rbg(13,13,13);
}

span,h1 {
    font-weight: 300;
    color:rbg(13,13,13);
}
.cover{
    border-radius: 5px;
   
}
</style>