<template>
  <div class="About" style="text-align:center">

<div class=WordSection1>

<h1><span lang=EN-US>Informativa sulla Privacy</span></h1>

<p class=Textbody><span lang=EN-US>MANSHIGUANG.IT RACCOGLIE ALCUNI DATI
PERSONALI DEI PROPRI UTENTI.</span></p>

<p class=Textbody><span lang=EN-US>La presente Informativa sulla privacy ��
entrata in vigore dalla data dell'ultimo aggiornamento, il&nbsp;9&nbsp;Agosto
2020.</span></p>

<p class=Textbody><span lang=EN-US>Questo documento pu�� essere stampato
utilizzando il comando di stampa presente nelle impostazioni di qualsiasi
browser.&nbsp;</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Il
Titolare:&nbsp;MAN SHIGUANG DI WANG MEIHONG</span></span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Come raccogliamo
i tuoi dati</span></span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>quando ce lo fornisci (ad es. contattandoci,
effettuando un ordine sul nostro sito web, iscrivendoti alla nostra newsletter
via e-mail, rispondendo a un quiz o creando un account);</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>dal tuo utilizzo del nostro sito web,
utilizzando cookie e tecnologie simili;</span></p>

<p class=Textbody style='margin-left:35.35pt;text-indent:-14.15pt'><span
lang=EN-US style='font-family:OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>occasionalmente, da terze parti.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Tipologie di dati
raccolti:&nbsp;</span></span><span lang=EN-US>email, nome e cognome, indirizzo
di spedizione, indirizzo di fatturazione, numero di telefono,&nbsp;data di
nascita, dati di pagamento, password, indirizzo IP, browser Internet e il
dispositivo utilizzato, la data e l��ora in cui effettui le visite e come
utilizzi il nostro sito web e accede ai suoi contenuti, se effettui un ordine,
la cronologia degli acquisti e pagamenti.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Modalit�� di
trattamento dei dati raccolti.&nbsp;</span></span><span lang=EN-US>Il Titolare
adotta le opportune misure di sicurezza volte ad impedire l��accesso, la
divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
Il trattamento viene effettuato mediante strumenti informatici e/o telematici,
con modalit�� organizzative e con logiche strettamente correlate alle finalit��
indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati
altri soggetti coinvolti nell��organizzazione di questo Sito Web (personale
amministrativo, commerciale, marketing, legali, amministratori di sistema)
ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri
postali, hosting provider, societ�� informatiche, agenzie di comunicazione)
nominati anche, se necessario, Responsabili del Trattamento da parte del
Titolare. L��elenco aggiornato dei Responsabili potr�� sempre essere richiesto al
Titolare del Trattamento.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Periodo di
conservazione dei dati.&nbsp;</span></span><span lang=EN-US>I Dati sono
trattati e conservati solo per il tempo&nbsp;necessario&nbsp;alle finalit�� per
le quali sono stati raccolti o perch�� obbligo di legge o per ordine di
un��autorit��.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Base giuridica
del trattamento.&nbsp;</span></span><span lang=EN-US>Il Titolare tratta Dati
Personali relativi all��Utente in caso sussista una delle seguenti condizioni:</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>l��Utente ha prestato il consenso per una o pi��
finalit�� specifiche; Nota: in alcuni ordinamenti il Titolare pu�� essere
autorizzato a trattare Dati Personali senza che debba sussistere il consenso
dell��Utente o un��altra delle basi giuridiche specificate di seguito, fino a
quando l��Utente non si opponga (��opt-out��) a tale trattamento. Ci�� non ��
tuttavia applicabile qualora il trattamento di Dati Personali sia regolato
dalla legislazione europea in materia di protezione dei Dati Personali;</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>il trattamento �� necessario all'esecuzione di un
contratto con l��Utente e/o all'esecuzione di misure precontrattuali;</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>il trattamento �� necessario per adempiere un
obbligo legale al quale �� soggetto il Titolare;</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>il trattamento �� necessario per l'esecuzione di
un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui ��
investito il Titolare;</span></p>

<p class=Textbody style='margin-left:35.35pt;text-indent:-14.15pt'><span
lang=EN-US style='font-family:OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>il trattamento �� necessario per il perseguimento
del legittimo interesse del Titolare o di terzi.</span></p>

<p class=Textbody><span lang=EN-US>&Egrave; comunque sempre possibile richiedere al
Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in
particolare di specificare se il trattamento sia basato sulla legge, previsto
da un contratto o necessario per concludere un contratto.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Luogo.&nbsp;</span></span><span
lang=EN-US>I Dati sono trattati presso le sedi operative del Titolare ed in
ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate.
Per ulteriori informazioni, contatta il Titolare.<br>
I Dati Personali dell��Utente potrebbero essere trasferiti in un paese diverso
da quello in cui l��Utente si trova. Per ottenere ulteriori informazioni sul
luogo del trattamento l��Utente pu�� fare riferimento alla sezione relativa ai
dettagli sul trattamento dei Dati Personali.&nbsp;Il Titolare potrebbe&nbsp;trasferire
tali informazioni in paesi al di fuori di Unione Europea e Svizzera, ad esempio
negli Stati Uniti.&#8239;Nella misura in cui il Titolare trasferisce le informazioni
al di fuori di Unione Europea o Svizzera, tale trasferimento �� disciplinato da
un accordo che incorpora le clausole contrattuali standard in conformit��
all'articolo 46 del regolamento (UE) 2016/679 del Parlamento europeo e del
Consiglio del 27 Aprile 2016 (&quot;Regolamento generale per la protezione dei
dati&quot;).&#8239;</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Divulgazione
delle tue informazioni a terze parti</span></span><span lang=EN-US>: solo nella
misura necessaria per gestire la nostra attivit��, ai nostri fornitori di
servizi e per adempiere a qualsiasi contratto che stipuliamo con te, e ove
richiesto dalla legge o per far valere i nostri diritti legali. Non vendiamo
informazioni personali e non venderemo informazioni personali ad eccezione di
quanto descritto nella presente Informativa sulla privacy.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>I tuoi diritti ai
sensi del GDPR in relazione alle tue informazioni</span></span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>accedere alle tue informazioni e per ricevere
informazioni sul loro utilizzo</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>far correggere e / o completare le tue
informazioni</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>far cancellare le tue informazioni</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>limitare l'uso delle tue informazioni</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>ricevere le tue informazioni in un formato
portatile</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>opporsi all'uso delle tue informazioni</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>revocare il consenso all'uso delle informazioni</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>proporre reclamo a un'autorit�� di controllo</span></p>

<p class=Textbody style='margin-left:35.35pt;text-indent:-14.15pt'><span
lang=EN-US style='font-family:OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>Informazioni personali sensibili: non
raccogliamo informazioni personali sensibili su di te.</span></p>

<h4><span class=StrongEmphasis><span lang=EN-US style='font-weight:normal'>Titolare
del Trattamento dei Dati</span></span></h4>

<p class=Textbody><span lang=EN-US>Ragione Sociale: MAN SHIGUANG DI WANG
MEIHONG</span></p>

<p class=Textbody><span lang=EN-US>VIA TEVERE 102/7</span></p>

<p class=Textbody><span lang=EN-US>50019 OSMANNORO</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Indirizzo email
del Titolare:</span></span><span lang=EN-US>&nbsp;info@manshiguang.it</span></p>

<h4><span class=StrongEmphasis><span lang=EN-US style='font-weight:normal'>Tipologie
di Dati raccolti</span></span></h4>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Informazioni
raccolte automaticamente quando visiti il nostro Sito Web</span></span><span
lang=EN-US>: indirizzo IP, browser Internet e il dispositivo utilizzato, la
data e l��ora in cui effettui le visite e come utilizzi il nostro sito web e
accede ai suoi contenuti, se effettui un ordine, la cronologia degli acquisti e
pagamenti</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Informazioni
fornite quando crei un account o effettui un acquisto</span></span><span
lang=EN-US>: email, nome e cognome, indirizzo di spedizione, indirizzo di
fatturazione, numero di telefono, dati di pagamento, data di nascita, password.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Informazioni
fornite quando ci contatti attraverso il nostro Servizio Clienti</span></span><span
lang=EN-US>: email, nome e cognome, numero di telefono.&nbsp;</span></p>

<p class=Textbody><span lang=EN-US>I Dati Personali possono essere liberamente
forniti dall'Utente o raccolti automaticamente durante l'uso di questo Sito
Web.<br>
Se non diversamente specificato, tutti i Dati richiesti da questo Sito Web sono
obbligatori. Se l��Utente rifiuta di comunicarli, potrebbe essere impossibile
per questo Sito Web fornire il Servizio. Nei casi in cui questo Sito Web
indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal
comunicare tali Dati, senza che ci�� abbia alcuna conseguenza sulla
disponibilit�� del Servizio o sulla sua operativit��.<br>
Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono
incoraggiati a contattare il Titolare.<br>
L��eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da
parte di questo Sito Web o dei titolari dei servizi terzi utilizzati da questo
Sito Web, ove non diversamente precisato, ha la finalit�� di fornire il Servizio
richiesto dall'Utente, oltre alle ulteriori finalit�� descritte nel presente
documento e nella Cookie Policy, se disponibile.</span></p>

<p class=Textbody><span lang=EN-US>L'Utente si assume la responsabilit�� dei
Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questo Sito
Web e garantisce di avere il diritto di comunicarli o diffonderli, liberando il
Titolare da qualsiasi responsabilit�� verso terzi.</span></p>

<h4><span lang=EN-US>Finalit�� del Trattamento dei Dati raccolti</span></h4>

<p class=Textbody><span lang=EN-US>I Dati dell��Utente sono raccolti per
consentire al Titolare di fornire il Servizio, adempiere agli obblighi di
legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed
interessi (o quelli di Utenti o di terze parti), individuare eventuali attivit��
dolose o fraudolente. Il Titolare pu�� anche fornire i Dati raccolti dell'Utente
ad altre societ��, che potrebbero elaborare le informazione per conto del
Titolare, tra cui societ�� che assistono con:</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>Il completamento o la consegnare del tuo ordine;</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>L'archiviazione e la protezione dei dati;</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>L'elaborazione dei pagamenti;</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>Le attivit�� di marketing, le analisi e il
rilevamento di frodi; o</span></p>

<p class=Textbody style='margin-left:35.35pt;text-indent:-14.15pt'><span
lang=EN-US style='font-family:OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US>servizio di assistenza clienti.</span></p>

<p class=Textbody><span lang=EN-US>Il Titolare pu�� condividere condividere i
Dati dell'Utente&nbsp;come richiesto o permesso dalla legge, ad esempio, se
dovesse ritenere che tale divulgazione sia necessaria per cooperare con
eventuali indagini investigative in materia di possibili attivit�� illecite, per
far rispettare i termini e le condizioni d'uso o proteggere i diritti o le
propriet�� nostri o di altri.</span></p>

<p class=Textbody><span lang=EN-US>Infine,&nbsp;il Titolare
pu��&nbsp;fornire&nbsp;i Dati dell'Utente&nbsp;a una o pi�� terze parti in caso
di fusione, acquisizione o riorganizzazione aziendale.</span></p>

<h4><span lang=EN-US>Permessi Facebook richiesti da questo Sito Web</span></h4>

<p class=Textbody><span lang=EN-US>Questo Sito Web pu�� richiedere alcuni
permessi Facebook che le consentono di eseguire azioni con l��account Facebook
dell��Utente e di raccogliere informazioni, inclusi Dati Personali, da esso.
Questo servizio permette a questo Sito Web di connettersi con l'account
dell'Utente sul social network Facebook, fornito da Facebook Inc.<br>
Per maggiori informazioni sui permessi che seguono, fai riferimento alla&nbsp;<a
href="https://developers.facebook.com/docs/facebook-login/permissions"><span
style='color:windowtext;text-decoration:none'>documentazione dei permessi
Facebook</span></a>&nbsp;ed alla&nbsp;<a
href="https://www.facebook.com/about/privacy/"><span style='color:windowtext;
text-decoration:none'>privacy policy di Facebook</span></a>.<br>
<span class=StrongEmphasis>Informazioni di base.&nbsp;</span>Le informazioni di
base dell��Utente registrato su Facebook che normalmente includono i seguenti
Dati: id, nome, immagine, genere e lingua di localizzazione ed, in alcuni casi
gli ��Amici�� di Facebook. Se l'Utente ha reso disponibili pubblicamente Dati
ulteriori, gli stessi saranno disponibili.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Accesso ai dati
privati.&nbsp;</span></span><span lang=EN-US>Permette l'accesso ai dati privati
dell'Utente e degli amici.&nbsp;</span></p>

<h4><span lang=EN-US>Dettagli sul trattamento dei Dati Personali</span></h4>

<p class=Textbody><span lang=EN-US>I Dati Personali sono raccolti per le
seguenti finalit�� ed utilizzando i seguenti servizi:</span></p>

<h6><span lang=EN-US>Servizi per statistica e analisi dei Dati</span></h6>

<p class=Textbody><span lang=EN-US>I servizi contenuti nella presente sezione
permettono al Titolare del Trattamento di monitorare e analizzare i dati di
traffico e servono a tener traccia del comportamento dell��Utente.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Google Analytics
(Google Ireland Limited)</span></span></p>

<p class=Textbody><span lang=EN-US>Google Analytics �� un servizio di analisi
web fornito da Google Ireland Limited (��Google��). Google utilizza i Dati
Personali raccolti allo scopo di tracciare ed esaminare l��utilizzo di questo
Sito Web, compilare report e condividerli con gli altri servizi sviluppati da
Google.<br>
Google potrebbe utilizzare i Dati Personali per contestualizzare e
personalizzare gli annunci del proprio network pubblicitario.</span></p>

<p class=Textbody><span lang=EN-US>Dati Personali trattati: Cookie; Dati di
utilizzo.</span></p>

<p class=Textbody><span lang=EN-US>Luogo del trattamento: Irlanda �C&nbsp;<a
href="https://policies.google.com/privacy"><span style='color:windowtext;
text-decoration:none'>Privacy Policy</span></a>&nbsp;�C&nbsp;<a
href="https://tools.google.com/dlpage/gaoptout?hl=en"><span style='color:windowtext;
text-decoration:none'>Opt Out</span></a>. Soggetto aderente al Privacy Shield.</span></p>

<h6><span class=StrongEmphasis><span lang=EN-US style='font-weight:normal'>Servizi
di piattaforma e hosting</span></span></h6>

<p class=Textbody><span lang=EN-US>Questi servizi hanno lo scopo di ospitare e
far funzionare componenti chiave di questo Sito Web, rendendo possibile
l��erogazione di questo Sito Web da un��unica piattaforma. Queste piattaforme
forniscono al Titolare un'ampia gamma di strumenti quali, ad esempio, strumenti
analitici, per la gestione della registrazione degli utenti, per la gestione
dei commenti e del database, per il commercio elettronico, per l��elaborazione
dei pagamenti etc. L��uso di tali strumenti comporta la raccolta e il
trattamento di Dati Personali.<br>
Alcuni di questi servizi funzionano attraverso server dislocati geograficamente
in luoghi differenti, rendendo difficile la determinazione del luogo esatto in
cui vengono conservati i Dati Personali.</span></p>

<h6><span class=StrongEmphasis><span lang=EN-US style='font-weight:normal'>Gestione
dei pagamenti</span></span></h6>

<p class=Textbody><span lang=EN-US>Se non diversamente specificato, questo Sito
Web elabora tutti i pagamenti con carta di credito, bonifico bancario o altri
mezzi tramite fornitori esterni di servizi di pagamento. In generale, e salvo
diversa indicazione, gli Utenti sono pregati di fornire i dettagli di pagamento
e le informazioni personali direttamente a tali fornitori di servizi di
pagamento.<br>
Questo Sito Web non �� coinvolta nella raccolta e nell'elaborazione di tali
informazioni: ricever�� invece solo una notifica da parte del fornitore di
servizi di pagamento in questione circa l'avvenuto pagamento.</span></p>

<p class=Textbody><span lang=EN-US>&nbsp;</span></p>

<h4><a name="cookie_policy"></a><span lang=EN-US>Cookie Policy</span></h4>

<p class=Textbody><span lang=EN-US>Questo Sito Web fa utilizzo
di&nbsp;tecnologie che consentono a questo Sito Web di raggiungere gli scopi
descritti di seguito. Tali tecnologie permettono al Titolare di raccogliere e
salvare informazioni (per esempio tramite l��utilizzo di Cookie) o di utilizzare
risorse (per esempio eseguendo uno script) sul dispositivo dell��Utente quando
quest��ultimo interagisce con questo Sito Web.</span></p>

<p class=Textbody><span lang=EN-US>Per semplicit��,&nbsp;di seguito tali
tecnologie sono sinteticamente definite ��Strumenti di Tracciamento��, salvo vi
sia ragione di differenziare.<br>
Per esempio, sebbene i Cookie possano essere usati in browser sia web sia
mobili, sarebbe fuori luogo parlare di Cookie nel contesto di applicazioni per
dispositivi mobili, dal momento che si tratta di Strumenti di Tracciamento che
richiedono la presenza di un browser. Per questo motivo, all��interno di questo
documento il temine Cookie �� utilizzato solo per indicare in modo specifico
quel particolare tipo di Strumento di Tracciamento.</span></p>

<p class=Textbody><span lang=EN-US>Alcune delle finalit�� per le quali vengono
impiegati Strumenti di Tracciamento potrebbero, inoltre richiedere il consenso
dell��Utente. Se viene prestato il consenso, esso pu�� essere revocato
liberamente in qualsiasi momento seguendo le istruzioni contenute in questo
documento.</span></p>

<p class=Textbody><span lang=EN-US>Questo Sito Web utilizza solo Strumenti di
Tracciamento gestiti direttamente dal Titolare (comunemente detti Strumenti di
Tracciamento ��di prima parte��).<br>
Durata e scadenza dei Cookie di prima parte e degli altri Strumenti di
Tracciamento simili possono variare a seconda di quanto impostato dal Titolare.
Alcuni di essi scadono al termine della sessione di navigazione dell��Utente.</span></p>

<h6><span lang=EN-US>Tipologie di cookie utilizzati da questo Sito Web</span></h6>

<p class=Textbody><em><span lang=EN-US style='font-family:"Liberation Serif",serif'>Cookie
assolutamente necessari</span></em><span lang=EN-US><br>
Questi cookie sono strettamente necessari per fornire i servizi richiesti e
sono essenziali per navigare sul sito web e utilizzarne le funzioni, quali il
carrello e l��acquisto dei prodotti.</span></p>

<p class=Textbody><em><span lang=EN-US style='font-family:"Liberation Serif",serif'>Cookie
statistici</span></em><span lang=EN-US><br>
Questi cookie raccolgono informazioni anonime sulle pagine visitate e
informazioni che riguardano i visitatori del sito web, ad esempio quali pagine
vengono visitate con maggiore frequenza e quali errori vengono visualizzati.
Non raccolgono informazioni che identificano un visitatore e vengono usati solo
per migliorare il funzionamento del sito web.</span></p>

<p class=Textbody><em><span lang=EN-US style='font-family:"Liberation Serif",serif'>Cookie
funzionali</span></em><span lang=EN-US><br>
Questi cookie permettono al sito web di ricordare le scelte effettuate (quali
nome, lingua o regione in cui ci si trova) e forniscono caratteristiche
ottimizzate e personalizzate, tra cui l'accesso ai prodotti che hai creato o
agli ordini che hai effettuato. Questi cookies sono anche utilizzati per
ricordare le preferenze circa la dimensione del testo, i caratteri e altre
caratteristiche personalizzabili.&#8239;</span></p>

<p class=Textbody><em><span lang=EN-US style='font-family:"Liberation Serif",serif'>Cookie
pubblicitari</span></em><span lang=EN-US><br>
Questi cookie sono utilizzati per presentare campagne promozionali di
potenziale interesse. Sono anche utilizzati per limitare il numero di volte in
cui si visualizza una campagna e per misurarne l��efficacia. Questi cookie sono
di solito forniti da terze parti.</span></p>

<p class=Textbody><em><span lang=EN-US style='font-family:"Liberation Serif",serif'>Cookie
di terze parti</span></em><span lang=EN-US><br>
Quando si utilizza&nbsp;questo Sito Web, �� possibile che vengano visualizzati
contenuti forniti da una societ�� diversa dalla nostra. Inoltre, se si condivide
i contenuti di&nbsp;questo&nbsp;Sito Web&nbsp;con gli amici tramite social
network, come Facebook o Twitter, potresti ricevere dei cookie da questi social
network.&nbsp;Questo&nbsp;Sito Web non ha&nbsp;accesso o controllo sui cookie
utilizzati da queste aziende o da siti web di terzi.</span></p>

<h6><span lang=EN-US>Come gestire preferenze o revocare il consenso</span></h6>

<p class=Textbody><span lang=EN-US>Esistono vari modi per gestire le preferenze
relative agli Strumenti di Tracciamento e per prestare o revocare il consenso,
ove necessario:</span></p>

<p class=Textbody><span lang=EN-US>Gli Utenti possono gestire le preferenze
relative agli Strumenti di Tracciamento direttamente tramite le impostazioni
dei propri dispositivi - per esempio, possono impedire l��uso o l��archiviazione
di Strumenti di Tracciamento.</span></p>

<p class=Textbody><span lang=EN-US>In aggiunta, ogni qualvolta l��utilizzo di
Strumenti di Tracciamento dipenda da consenso, l��Utente pu�� prestare o revocare
tale consenso impostando le proprie preferenze all��interno dell��informativa sui
cookie o aggiornando tali preferenze tramite il widget delle impostazioni di
tracciamento, se presente.</span></p>

<p class=Textbody><span lang=EN-US>Grazie ad apposite funzioni del browser o
del dispositivo �� anche possibile rimuovere Strumenti di Tracciamento
precedentemente salvati.</span></p>

<p class=Textbody><span lang=EN-US>Altri Strumenti di Tracciamento presenti
nella memoria locale del browser possono essere rimossi cancellando la
cronologia di navigazione.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Individuare le
impostazioni relative agli Strumenti di Tracciamento</span></span></p>

<p class=Textbody><span lang=EN-US>Gli Utenti possono, per esempio, trovare
informazioni su come gestire i Cookie in alcuni dei browser pi�� diffusi ai
seguenti indirizzi:</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies"><span
style='color:windowtext;text-decoration:none'>Google Chrome</span></a></span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"><span
style='color:windowtext;text-decoration:none'>Mozilla Firefox</span></a></span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/"><span
style='color:windowtext;text-decoration:none'>Apple Safari</span></a></span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"><span
style='color:windowtext;text-decoration:none'>Microsoft Internet Explorer</span></a></span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://support.microsoft.com/it-it/help/4027947%20(https:/support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies)"><span
style='color:windowtext;text-decoration:none'>Microsoft Edge</span></a></span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"><span
style='color:windowtext;text-decoration:none'>Brave</span></a></span></p>

<p class=Textbody style='margin-left:35.35pt;text-indent:-14.15pt'><span
lang=EN-US style='font-family:OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://help.opera.com/en/latest/web-preferences/#cookies"><span
style='color:windowtext;text-decoration:none'>Opera</span></a>.</span></p>

<p class=Textbody><span lang=EN-US>Gli Utenti possono inoltre gestire alcuni
Strumenti di Tracciamento per applicazioni mobili disattivandoli tramite le
apposite impostazioni del dispositivo, quali le impostazioni di pubblicit�� per
dispositivi mobili o le impostazioni relative al tracciamento in generale (gli
Utenti possono consultare le impostazioni del dispositivo per individuare quella
pertinente).</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Iniziative
dell'industria pubblicitaria per la gestione dei consensi</span></span></p>

<p class=Textbody><span lang=EN-US>Fermo restando quanto precede, si informano
gli Utenti della possibilit�� di avvalersi delle informazioni presenti su&nbsp;<a
href="http://www.youronlinechoices.eu/"><span style='color:windowtext;
text-decoration:none'>YourOnlineChoices</span></a>&nbsp;(EU),&nbsp;<a
href="https://www.networkadvertising.org/understanding-digital-advertising"><span
style='color:windowtext;text-decoration:none'>Network Advertising Initiative</span></a>&nbsp;(USA)
e&nbsp;<a href="https://www.aboutads.info/consumers/"><span style='color:windowtext;
text-decoration:none'>Digital Advertising Alliance</span></a>&nbsp;(USA),&nbsp;<a
href="https://youradchoices.ca/understanding-online-advertising/"><span
style='color:windowtext;text-decoration:none'>DAAC</span></a>&nbsp;(Canada),&nbsp;<a
href="http://www.ddai.info/optout"><span style='color:windowtext;text-decoration:
none'>DDAI</span></a>&nbsp;(Giappone) o altri servizi analoghi. Con questi
servizi �� possibile gestire le preferenze di tracciamento della maggior parte
degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di
utilizzare tali risorse in aggiunta alle informazioni fornite nel presente
documento.</span></p>

<p class=Textbody><span lang=EN-US>La Digital Advertising Alliance mette
inoltre a disposizione un��applicazione chiamata&nbsp;<a
href="https://youradchoices.com/appchoices"><span style='color:windowtext;
text-decoration:none'>AppChoices</span></a>&nbsp;che aiuta gli Utenti a
controllare la pubblicit�� comportamentale sulle applicazioni mobili.</span></p>

<h4><span lang=EN-US>Protezione dei Dati</span></h4>

<p class=Textbody><span lang=EN-US>Il Titolare implementa misure tecniche e
organizzative appropriate come l'archiviazione delle informazioni su server
protetti, la crittografia dei trasferimenti di dati da o verso i nostri server
utilizzando la tecnologia Secure Sockets Layer (SSL), la crittografia dei
pagamenti effettuati su o tramite il nostro Sito Web utilizzando la tecnologia
Secure Sockets Layer (SSL) , concedendo l'accesso alle tue informazioni solo
ove necessario e solo da persone di fiducia che sono state addestrate e
informate sulla gestione appropriata delle informazioni personali. Una volta
aperto un account su questo Sito Web, sar�� possibile scegliere una password che
aiuti a proteggerne le informazioni. &Egrave; necessario scegliere una password sicura
e conservarla con cura. L'Utente potr�� cambiare password cliccando&nbsp;<a
href="https://www.oishiiplanet.it/account/login#recover"><span
style='color:windowtext;text-decoration:none'>qui</span></a>&nbsp;e ricevendo
un'email per reimpostare la password.</span></p>

<h4><span class=StrongEmphasis><span lang=EN-US style='font-weight:normal'>Diritti
dell��Utente</span></span></h4>

<p class=Textbody><span lang=EN-US>Gli Utenti possono esercitare determinati
diritti con riferimento ai Dati trattati dal Titolare. In particolare, l��Utente
ha il diritto di:</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=StrongEmphasis><span lang=EN-US>revocare il consenso
in ogni momento.</span></span><span lang=EN-US>L��Utente pu�� revocare il
consenso al trattamento dei propri Dati Personali precedentemente espresso.</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=StrongEmphasis><span lang=EN-US>opporsi al
trattamento dei propri Dati.</span></span><span lang=EN-US>L��Utente pu�� opporsi
al trattamento dei propri Dati quando esso avviene su una base giuridica
diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono
indicati nella sezione sottostante.</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=StrongEmphasis><span lang=EN-US>accedere ai propri
Dati.</span></span><span lang=EN-US>L��Utente ha diritto ad ottenere
informazioni sui Dati trattati dal Titolare, su determinati aspetti del
trattamento ed a ricevere una copia dei Dati trattati.</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=StrongEmphasis><span lang=EN-US>verificare e chiedere
la rettificazione.</span></span><span lang=EN-US>L��Utente pu�� verificare la
correttezza dei propri Dati e richiederne l��aggiornamento o la correzione.</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=StrongEmphasis><span lang=EN-US>ottenere la
limitazione del trattamento.</span></span><span lang=EN-US>Quando ricorrono
determinate condizioni, l��Utente pu�� richiedere la limitazione del trattamento
dei propri Dati. In tal caso il Titolare non tratter�� i Dati per alcun altro
scopo se non la loro conservazione.</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=StrongEmphasis><span lang=EN-US>ottenere la
cancellazione o rimozione dei propri Dati Personali.</span></span><span
lang=EN-US>Quando ricorrono determinate condizioni, l��Utente pu�� richiedere la
cancellazione dei propri Dati da parte del Titolare.</span></p>

<p class=Textbody style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span lang=EN-US style='font-family:
OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=StrongEmphasis><span lang=EN-US>ricevere i propri
Dati o farli trasferire ad altro titolare.</span></span><span lang=EN-US>L��Utente
ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e
leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne
il trasferimento senza ostacoli ad un altro titolare. Questa disposizione ��
applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento
�� basato sul consenso dell��Utente, su un contratto di cui l��Utente �� parte o su
misure contrattuali ad esso connesse.</span></p>

<p class=Textbody style='margin-left:35.35pt;text-indent:-14.15pt'><span
lang=EN-US style='font-family:OpenSymbol'>&#8226;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span class=StrongEmphasis><span lang=EN-US>proporre reclamo.</span></span><span
lang=EN-US>L��Utente pu�� proporre un reclamo all��autorit�� di controllo della
protezione dei dati personali competente o agire in sede giudiziale.</span></p>

<h6><span class=StrongEmphasis><span lang=EN-US style='font-weight:normal'>Dettagli
sul diritto di opposizione</span></span></h6>

<p class=Textbody><span lang=EN-US>Quando i Dati Personali sono trattati
nell��interesse pubblico, nell��esercizio di pubblici poteri di cui �� investito
il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti
hanno diritto ad opporsi al trattamento per motivi connessi alla loro
situazione particolare. Si fa presente agli Utenti che, ove i loro Dati fossero
trattati con finalit�� di marketing diretto, possono opporsi al trattamento
senza fornire alcuna motivazione.</span></p>

<h6><span class=StrongEmphasis><span lang=EN-US style='font-weight:normal'>Come
esercitare i diritti</span></span></h6>

<p class=Textbody><span lang=EN-US>Per esercitare i diritti dell��Utente, gli
Utenti possono indirizzare una richiesta all'email info@manshiguang.it. Le
richieste sono depositate a titolo gratuito e evase dal Titolare nel pi�� breve
tempo possibile, in ogni caso entro un mese.</span></p>

<h4><span class=StrongEmphasis><span lang=EN-US style='font-weight:normal'>Ulteriori
informazioni sul trattamento</span></span></h4>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Difesa in
giudizio.&nbsp;</span></span><span lang=EN-US>I Dati Personali dell��Utente
possono essere utilizzati da parte del Titolare in giudizio o nelle fasi
preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo
di questo Sito Web o dei Servizi connessi da parte dell��Utente. L��Utente
dichiara di essere consapevole che il Titolare potrebbe essere obbligato a
rivelare i Dati per ordine delle autorit�� pubbliche.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Informative
specifiche.&nbsp;</span></span><span lang=EN-US>Su richiesta dell��Utente, in
aggiunta alle informazioni contenute in questa privacy policy, questo Sito Web
potrebbe fornire all'Utente delle informative aggiuntive e contestuali
riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati
Personali.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Log di sistema e
manutenzione.&nbsp;</span></span><span lang=EN-US>Per necessit�� legate al
funzionamento ed alla manutenzione, questo Sito Web e gli eventuali servizi
terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che
registrano le interazioni e che possono contenere anche Dati Personali, quali
l��indirizzo IP Utente.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Informazioni non
contenute in questa policy.&nbsp;</span></span><span lang=EN-US>Ulteriori
informazioni in relazione al trattamento dei Dati Personali potranno essere
richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli
estremi di contatto.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Risposta alle
richieste ��Do Not Track��.&nbsp;</span></span><span lang=EN-US>Questo Sito Web
non supporta le richieste ��Do Not Track��. Per scoprire se gli eventuali servizi
di terze parti utilizzati le supportino, l'Utente �� invitato a consultare le
rispettive privacy policy.</span></p>

<p class=Textbody><span class=StrongEmphasis><span lang=EN-US>Modifiche a
questa privacy policy.&nbsp;</span></span><span lang=EN-US>Il Titolare del
Trattamento si riserva il diritto di apportare modifiche alla presente privacy
policy in qualunque momento notificandolo agli Utenti su questa pagina e, se
possibile, su questo Sito Web nonch��, qualora tecnicamente e legalmente
fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di
contatto di cui �� in possesso. Si prega dunque di consultare con frequenza
questa pagina, facendo riferimento alla data di ultima modifica indicata in
fondo.<br>
Qualora le modifiche interessino trattamenti la cui base giuridica �� il
consenso, il Titolare provveder�� a raccogliere nuovamente il consenso
dell��Utente, se necessario.</span></p>

<p class=Standard><span lang=EN-US>&nbsp;</span></p>

</div>

  </div>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';

export default {
  name: 'About',
  data() {
    return {
      num1: 0,
      newList: []
    };
  },
  created() {
    this.$emit('update:layout', LayoutDefault);
  },
};
</script>
<style>
 h1
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	page-break-after:avoid;
	text-autospace:ideograph-other;
	font-size:24.0pt;
	font-family:"Liberation Serif",serif;}
h4
	{margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	page-break-after:avoid;
	text-autospace:ideograph-other;
	font-size:12.0pt;
	font-family:"Liberation Serif",serif;}
h6
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	page-break-after:avoid;
	text-autospace:ideograph-other;
	font-size:7.0pt;
	font-family:"Liberation Serif",serif;}
p.Standard, li.Standard, div.Standard
	{mso-style-name:Standard;
	margin:0cm;
	text-autospace:ideograph-other;
	font-size:12.0pt;
	font-family:"Liberation Serif",serif;}
p.Textbody, li.Textbody, div.Textbody
	{mso-style-name:"Text body";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:7.0pt;
	margin-left:0cm;
	line-height:120%;
	text-autospace:ideograph-other;
	font-size:12.0pt;
	font-family:"Liberation Serif",serif;}
span.StrongEmphasis
	{mso-style-name:"Strong Emphasis";
	font-weight:bold;}
.MsoChpDefault
	{font-size:12.0pt;
	font-family:"Liberation Serif",serif;}
.MsoPapDefault
	{text-autospace:ideograph-other;}
 /* Page Definitions */
 @page WordSection1
	{size:612.0pt 792.0pt;
	margin:2.0cm 2.0cm 2.0cm 2.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
</style>
