<template>
  <component :is="layout">
    <router-view v-model:layout="layout"/>
  </component>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      layout: 'div',
    };
  },created(){

  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.6;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, 'Futura', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding-top: 10px;
    padding-bottom:50px;
}
.center{
justify-content: center;
text-align: center;
}
.product-name{
  font-size:14px;
  color:#333;
  text-decoration: none;
  display: block;
  height: 40px;
  overflow: hidden;
  
}
.product-price{
  font-size:20px;
  color:#000;
  font-weight: 500;
  display: inline-block;
}
.product-discount{
  font-size:18px;
  color:#ccc;
  font-weight: 200;text-decoration:line-through;
  display: inline-block;
  margin-left:5px;
}
.el-input-number__decrease {
  background-color: #FF5E51;
  color:#fff;
  border:1px #FF5E51 solid;top:0;left:0;bottom:0;
}
.el-input-number__increase {
  background-color: #FF5E51;
  color:#fff;
  border:1px #FF5E51 solid;top:0;right: 0;bottom:0;
}
.el-input__wrapper {
  border:1px #FF5E51 solid;
  padding: 0;
  box-shadow:0;
}

.el-input__wrapper{
    border:1px solid #eee !important;
     padding: 5px 5px 5px 15px !important;
}
</style>
