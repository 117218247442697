<template>
    <div style="text-align:center;padding:20px">
        <p> <img src="@/assets/logo.png" class="logo" /></p>
        <p>
            <el-alert title="注销是一个不可逆的永久操作，注销后账号中的所有信息和数据都将被永久清空，请谨慎操作。" type="error" :closable="false"></el-alert>
            </p>
        <el-form label-position="top" label-width="80px">
            <el-form-item label="账号">
                <el-input v-model="loginInfo.phone"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input type="password" v-model="loginInfo.password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="destroy">注销账号</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
  
<script>
export default {
    name: 'Destroy',
    data() {
        return {
            loginInfo: { phone: "", password: "" }
        };
    },
    created() {
    }, methods: {
        destroy() {
            if (this.loginInfo.phone == "" || this.loginInfo.password == "") {
                this.$message.error('请输入您的账号和密码！');
                return;
            }
            this.$confirm('注销是一个不可逆的永久操作，注销后账号中的所有信息和数据都将被永久清空，确认要注销您在漫时光-购物上的账号？', '请注意！！', {
                distinguishCancelAndClose: true,
                confirmButtonText: '是的，我要注销我的账号',
                cancelButtonText: '放弃注销'
            })
                .then(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '保存修改'
                    // });
                    this.$httpClient({
                        url: "/api/main/loginNew",
                        data: {
                            phone: this.loginInfo.phone,
                            password: this.loginInfo.password,
                            code: "111111"
                        }
                    }, (data) => {
                        this.$setData("token", data.token);
                        this.$setData("secretKey", data.secretKey);
                        this.isLogin = true;
                        this.showLogin = false;
                        this.$httpClient({
							url: "/api/user/logOut",
							data: {
								token: data.token
							}
						}, (data) => {
                            this.$showMsg("账号注销申请已提交！");
						});
                       
                        this.loginInfo = {};
                    }, (res) => {
                        this.$showMsg("Email o password errati.", "warning");
                    }, true);

                });
        }
    }
}
</script>
  