<template>
    <h1>Carrello</h1>
    <el-row style="margin-top:20px" gutter="20" v-loading="loading">
        <el-col :span="2"></el-col>
        <el-col :span="14">
            <div style="text-align:right">
                <el-table :data="data.carts" class="tab">
                    <el-table-column prop="cover" label="PRODOTTO" width="120">
                        <template #default="scope">
                            <el-image :src="scope.row.cover" class="cover" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuName" label="" />
                    <el-table-column prop="num" label="QUANTITÀ" width="200">
                        <template #default="scope">
                            <Stepper :size="'default'" :data="scope.row" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="PREZZO" style="text-align:center" width="120">
                        <template #default="scope">
                            {{ scope.row.price }}€
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-col>
        <el-col :span="5">
            <el-card>
                <dl>
                    <dt>metodo di pagamento
                        <el-popover placement="bottom" :width="400" trigger="click" v-model="showPay">
                            <template #reference>
                                <el-icon style="cursor: pointer;">
                                    <Edit />
                                </el-icon>
                            </template>
                            <el-table :data="paymentMethods" @current-change="choosePaymentMethod"
                                highlight-current-row>
                                <el-table-column property="payName" label="metodo di pagamento" />
                            </el-table>
                        </el-popover>
                    </dt>
                    <dd>{{ paymentMethod.payName }}</dd>
                </dl>
                <dl>
                    <dt>indirizzo
                        <el-popover placement="bottom" :width="700" trigger="click">
                            <template #reference>
                                <el-icon style="cursor: pointer;">
                                    <Edit />
                                </el-icon>
                            </template>
                            <el-table :data="allAddresses" @current-change="chooseAddress" highlight-current-row>
                                <el-table-column width="200" property="areaJson" label="area" />
                                <el-table-column width="150" property="phone" label="phone" />
                                <el-table-column width="150" property="man" label="name" />
                                <el-table-column width="200" property="address" label="address" />
                            </el-table>
                        </el-popover>
                    </dt>
                    <dd>{{ address.areaJson[0] }},{{ address.areaJson[1] }},{{ address.areaJson[2] }},{{ address.address
                    }}</dd>
                    <dd>{{ address.man }},{{ address.phone }}</dd>
                </dl>
            </el-card>

            <el-card style="margin-top:20px">
                <template #header>
                    <div class="card-header">
                        <span>Riepilogo</span>
                        <el-popover placement="bottom" :width="300" trigger="click">
                            <template #reference>
                                <el-button style="margin-right: 16px;float: right;" type="danger" plain>Scegli un coupon
                                </el-button>
                            </template>
                            <el-table :data="allCoupons" highlight-current-row>
                                <el-table-column width="110" property="couponName" label="" />
                                <el-table-column width="110" property="amount" label="">
                                    <template #default="scope">
                                        <el-button size="small" type="danger" plain>-{{ scope.row.amount }}€</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column width="80" property="address" label="">
                                    <template #default="scope">
                                        <el-button size="small">
                                            选中
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-popover>


                    </div>
                </template>
                <el-row>
                    <el-col :span="10">commodity </el-col>
                    <el-col :span="14" style="text-align:right;height:35px">{{ price.totalFee }}€</el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">freight</el-col>
                    <el-col :span="14" style="text-align:right;height:35px">{{ price.expressFee }}€</el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">discount</el-col>
                    <el-col :span="14" style="text-align:right;color:red;height:35px">-{{ price.totalCoupon }}€</el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">totale</el-col>
                    <el-col :span="14" style="text-align:right;height:35px;font-weight: bold;">{{ price.totalAmount }}€
                    </el-col>
                </el-row>
            </el-card>

            <el-row>
                <el-col :span="24" style="padding:10px">
                    <el-button type="danger" style="width:100%;height:45px;font-size:20px;font-weight: 500;"
                        @click="createOrder">Confermare
                        l'ordine</el-button>
                </el-col>
            </el-row>
        </el-col>
        <el-col :span="3">
        </el-col>
    </el-row>

</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import Stepper from '@/components/Steppers.vue';
export default {
    name: 'Cart',
    data() {
        return {
            num1: 0,
            data: {},
            address: {
                areaJson: ['', '', '']
            },
            price: {},
            paymentMethods: [],
            allAddresses: [],
            paymentMethod: { payName: "-" },
            allCoupons: [],
            showPay: false,
            loading: false,
            payWindow: {}
        };
    },
    created() {
        this.$emit('update:layout', LayoutDefault);
        this.loading = true;
        this.getDefaultAddress(((ads) => {
            this.$httpClient({
                url: "api/cart/listWithShop",
                data: {
                    shop_id: 4
                }
            }, (data) => {
                if (data.total == 0)
                    return;
                this.data = data.list[0];
                var cartIds = this.data.carts.map(n => n.cartId);
                this.$httpClient({
                    url: "api/order_many/ready",
                    data: {
                        address_id: ads.addressId,
                        carts: cartIds,
                        coupon_id: ""
                    }
                }, (orderData) => {
                    console.log("--->", orderData);
                    this.price = orderData[0].price;
                    this.allCoupons = orderData[0].coupons;
                    this.paymentMethods = orderData[0].paymentMethods;
                    this.loading = false;
                }, (res) => { this.loading = false; }, true);
            }, (res) => { this.loading = false; }, true);

        }));
    }, methods: {
        getDefaultAddress(afterHandle) {
            this.$httpClient({
                url: "api/address/listNew",
                data: {
                }
            }, (data) => {
                if (data == null || data.length == 0) {
                    this.$showMsg("您还未设置送货地址，请先设定一个送货地址", "error");
                    return;
                }
                this.address = data.find(n => n.defaultFlag == "1");
                if (this.address == null) {
                    this.address = data[0];
                }
                if (afterHandle != undefined) {
                    afterHandle(this.address);
                }
                this.allAddresses = data;

            }, (res) => { }, true);
        }, choosePaymentMethod(e) {
            this.paymentMethod = e;
            this.showPay = false;
        }, chooseAddress(e) {
            this.address = e;
        }, getPayStatus() {
            this.getOrderPayInfoTask = setInterval(() => {
                this.$httpClient({
                    url: "/api/pay/getOrderPayInfo",
                    data: {
                        payment_id: this.payment_id
                    },
                },
                    (data) => {
                        if (parseInt(data.flag) == 1) {
                            this.paycomplete = true;
                            this.payshow = true;
                            clearInterval(this.getOrderPayInfoTask);
                            this.$showMsg("支付成功", "info");
                            setTimeout(() => {
                                this.loading = false;
                                this.payWindow.close();
                                this.data = {};
                                this.$router.push({
                                    path: "/my"
                                });
                            }, 3000);
                        }
                    }
                );
            }, 5000);
        }, createOrder() {
            if (this.paymentMethod.payName == "-") {
                this.$showMsg("请选择支付方式", "error");
                return;
            }
            if (this.address.addressId == undefined || this.address.addressId == 0) {
                this.$showMsg("请选择地址", "error");
                return;
            }
            this.loading = true;
            var cartIds = this.data.carts.map(n => n.cartId);
            var orderReadyPostData = {
                address_id: this.address.addressId,
                carts: JSON.stringify(cartIds),
                coupon_id: '[]',
                origin: "pc",
                pay_method: this.paymentMethod.type
            };
            this.$httpClient({
                url: "api/order_many/create",
                data: orderReadyPostData
            }, (data) => {
                this.loading = false;
                this.$showMsg("下单成功");
                if (this.paymentMethod.type == 6) {
                    this.loading = true;
                    this.$httpClient({
                        url: "/api/pay/payOrder",
                        data: {
                            order_id: data.orderId.join(','),
                            pay_method: "worldline"
                        },
                    },
                        (res) => {
                            console.log(res)
                            this.payment_id = res.paymentId;
                            //plus.runtime.openWeb();
                            //var payUrl = res.worldlineUrl + "/en/circuits/" + res.paymentId + "/T7HESMA-A4W4Z09-KX3PK07-X3853W0";
                            var payUrl = res.worldlineUrl;
                            this.payWindow = window.open(payUrl);
                            setTimeout(() => {
                                this.getPayStatus();
                            }, 1000);
                        }, (res) => {
                            this.loading = false;
                            if (res.data.code == 2002) {
                                this.$showMsg("已完成的订单不能重复支付", "error");
                                setTimeout(() => {
                                    this.$router.push({
                                        path: "/my"
                                    }, 1500);
                                });
                            }
                        });
                    return;
                }
                this.data = {};
                this.$router.push({
                    path: "/my"
                });
            }, (res) => {
                this.loading = false;
                this.$showMsg("下单失败");
            }, true);

        }
    }, components: {
        Stepper
    }
};
</script>
<style scoped>
.cover {
    width: 64px;
    border-radius: 5px;

}

.tab {
    margin: 0 auto
}

.card-header {
    font-weight: 300;
    font-size: 20px;
    color: #131313
}

.total-amount {
    float: right;
    font-size: 20px;
    font-weight: 400;
    color: #131313
}

dl dd {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #131313
}

dl dt {
    display: block;
    font-size: 14px;
    font-weight: 600;
}

h1 {
    width: 100%;
    display: block;
    text-align: center;
    padding: 20px;
    font-weight: 300;
    color: #999;
}
</style>