<template>
    <el-input-number v-model="data.num" :size="size" :min="0" :step="parseInt(data.minToBuy)" :max="parseInt(data.stock)"
        @change="change($event, data)" />
</template>
<script>
export default {
    name: "Stepper",
    props: ["data", "size"],
    data() {
        return {
            cartGoods: { num: 0 }
        }
    },
    mounted() { },
    created() {
        if (this.size == "" || this.size == null)
            this.size = "'default'";
        
        if(this.data.num==undefined)
            this.data.num=0;
    },
    methods: {
        change(e, goods) {
            console.log(goods)
            this.$httpClient({
                url: '/api/cart/add',
                data: {
                    skuId: goods.skus[0].skuId,
                    num: goods.num
                }
            }, async (data) => {
                console.log(data);
            },(ex)=>{
                if(ex.data.status=="fail"){
                    this.$showMsg("per favore fai prima il log in","error");
                }
            });
        }
    }
}</script>
<style scoped>
</style>