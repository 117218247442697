<template>
    <el-row>
        <el-col class="center" style="padding:20px">
            <el-tag type="danger" size="large">
                {{ $route.query.categoryName }}
            </el-tag>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="2"></el-col>
        <el-col :span="2">
            <el-menu class="top-menu" mode="vertical">
                <template v-for="(item, index) in categoies" :key="index">
                    <el-menu-item @click="goCategory($event, item)">
                        {{ item.categoryName }}
                    </el-menu-item>
                </template>
            </el-menu>
        </el-col>
        <el-col :span="19">
            <el-row v-infinite-scroll="pageLoad">
                <el-col class="center" :span="22">
                    <el-space wrap>
                        <GoodsView :data="item" v-for="(item, idx) in goodsList" :key="idx"></GoodsView>
                    </el-space>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>
    <script>
    import LayoutDefault from '@/layouts/LayoutDefault.vue';
    import GoodsView from '@/components/GoodsView.vue';
    
    export default {
        name: 'Category',
        data() {
            return {
                num1: 0,
                goodsList: [],
                queryData: {
                    page: 0,
                    limit: 14
                }, categoies: []
            };
        },
        mounted() {
            this.getList(this.$route.query.categoryId);
            var findCate=this.$categoies.find(n => n.categoryId == this.$route.query.categoryId);
            const cate =findCate==undefined?[]: findCate.sons;
            this.$emit('update:layout', LayoutDefault);
            this.categoies = cate;
        }, watch: {
            '$route'(to, from) {
                //window.location.href="http://localhost:8080/#/list?type=2";
                this.queryData.page = 0;
                this.getList(to.query.categoryId)
            },
            immediate: true
        }, methods: {
            getList(categoryId) {
                if (this.queryData.page == 0)
                    this.goodsList = [];
                this.queryData.page += 1;
                this.queryData.categoryId = categoryId;
                this.$httpClient({
                    url: "/api/goods_spu/list",
                    data: this.queryData
                }, (data) => {
                    data.list.forEach((item) => {
                        this.goodsList.push(item);
                    });
                }, (res) => { }, true);
            }, goCategory(e, category) {
                this.$router.push({
                    path: "/category", query: {
                        categoryId: category.categoryId,
                        categoryName: category.categoryName
                    }
                })
            }, pageLoad() { this.getList(this.$route.query.categoryId); }
        },
        components: {
            GoodsView
        }
    };
    </script>
