<template>
  <div class="About" style="text-align:center">
    <h1>About</h1>
    <p>
      consumer hotline
      348-3765644、366-3880608<br><br>
      wechat
      lingshimanshiguang
    </p>
  </div>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';

export default {
  name: 'About',
  data() {
    return {
      num1: 0,
      newList: []
    };
  },
  created() {
    this.$emit('update:layout', LayoutDefault);
  },
};
</script>
