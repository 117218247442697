<template>
    <el-row>
        <el-col :span="4"></el-col>
        <el-col class="center" :span="16" style="padding:20px">
            <el-tabs v-model="activeName" class="demo-tabs" @tab-change="tabClick">
                <el-tab-pane label="Tutto" name="0"></el-tab-pane>
                <el-tab-pane label="Ordinato" name="1"></el-tab-pane>
                <el-tab-pane label="Distribuzione" name="2"></el-tab-pane>
                <el-tab-pane label="Balla" name="3"></el-tab-pane>
                <el-tab-pane label="Trasporto" name="4"></el-tab-pane>
                <el-tab-pane label="Fine" name="5"></el-tab-pane>
                <el-tab-pane label="Dopo le vendite" name="6"></el-tab-pane>
            </el-tabs>
        </el-col>
        <el-col :span="4"></el-col>
    </el-row>
    <el-row>
        <el-col :span="4"></el-col>
        <el-col class="center" :span="16" style="padding:0px 20px 20px 20px;">
        <el-empty v-if="orderList.length==0"></el-empty>
            <table style="width:100%;margin-bottom: 10px;" v-for="(item, index) in orderList" :key="index">
                <tbody v-for="(goods, gi) in item.goods" :key="gi">
                    <tr>
                        <td rowspan="2" style="width:130px;padding-bottom: 10px;padding-right: 10px;">
                            <el-image :src="goods.cover" width="120" />
                        </td>
                        <td colspan="5" v-if="gi == 0"
                            style="text-align:left;font-weight: 300;padding-left:20px;background-color: rgb(243, 243, 243);height:50px;">
                            <span>{{ item.createTime }}</span>
                            <span>&nbsp;&nbsp;&nbsp;numero d'ordine：{{ item.sn }}</span>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.payMethod }}</span>
                        </td>

                    </tr>
                    <tr>
                        <td style="width:100px">{{ goods.orderSkuId }}</td>
                        <td style="text-align:left;padding-left:20px;">{{ goods.spuName }}</td>
                        <td style="width:100px">{{ parseInt(goods.num) }}</td>
                        <td style="width:100px">{{ goods.amount }}€</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr><td colspan="5" style="text-align:right;font-weight: bold;">totale：{{item.totalAmount}}€</td></tr>
                </tfoot>
            </table>
        </el-col>
        <el-col :span="4"></el-col>
    </el-row>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';

export default {
    name: 'My',
    data() {
        return {
            num1: 0,
            orderList: [],
            activeName: "0"
        };
    },
    created() {
        this.$emit('update:layout', LayoutDefault);
        this.getList(0);
    }, methods: {
        getList(flag) {
            this.$httpClient({
                url: "/api/order/list",
                data: {
                    flag: flag,
                    pageLimit: 10
                }
            }, (data) => {
                data.list.forEach(n => {
                    this.orderList.push(n);
                });
            }, (res) => { }, true);
        },tabClick(e){
            this.orderList=[];
           this.getList(e);

        }
    }
};
</script>
