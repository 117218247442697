<template>
  <el-row>
    <el-col>
      <el-affix :offset="0">
        <el-menu class="top-menu" mode="horizontal" @select="go">
          <el-menu-item index="1">
            <img src="@/assets/logo.png" class="logo" />
          </el-menu-item>
          <el-menu-item :index="'2'" data-default-lang="推荐商品">
            Raccomandare
          </el-menu-item>
          <el-menu-item :index="'3'" data-default-lang="最新上架">
            Nuovi arrivi
          </el-menu-item>
          <el-menu-item :index="'4'" data-default-lang="优惠商品">
            Offerte
          </el-menu-item>
          <el-sub-menu :index="'5'" data-default-lang="商品分类">
            <template #title>Categorie</template>
            <template v-for="(item, index) in $categoies" :key="index">
              <el-menu-item @click="goCategory($event, item)">{{ item.categoryName }}</el-menu-item>
            </template>
          </el-sub-menu>
          <el-menu-item :index="'6'" data-default-lang="关于我们">
            Chi siamo
          </el-menu-item>
          <el-menu-item :index="'8'">
            <el-popover placement="bottom" :width="300" trigger="click">
              <template #reference>
                <el-icon style="margin-top: 20px;">
                  <Search />
                </el-icon>
              </template>
              <div>
                <el-input placeholder="Type something" />
              </div>
            </el-popover>

          </el-menu-item>
          <el-menu-item :index="'9'">
            <el-icon style="margin-top: 20px;">
              <ShoppingCart />
            </el-icon><span data-default-lang="购物车"></span>
          </el-menu-item>
          <el-sub-menu :index="'10'" style="float: right;">
            <template #title>
              <el-icon>
                <User />
              </el-icon>
            </template>
            <el-menu-item :index="'104'" v-if="!isLogin">registrati</el-menu-item>
            <el-menu-item :index="'105'" v-if="!isLogin">accesso</el-menu-item>
            <el-menu-item :index="'100'" v-if="isLogin">ordine</el-menu-item>
            <el-menu-item :index="'101'" v-if="isLogin">informazione</el-menu-item>
            <el-menu-item :index="'102'" v-if="isLogin">indirizzo</el-menu-item>
            <el-menu-item :index="'106'" v-if="isLogin">uscire</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-affix>
    </el-col>

  </el-row>
  <el-row>
    <el-col>
      <el-carousel height="350px">
        <el-carousel-item v-for="item in 4" :key="item">
          <el-image :fit="'fill'"
            :src="'https://cdn.shopify.com/s/files/1/0398/9307/4080/files/shirataki-Full-Desktop.jpg?v=1657028720'">
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </el-col>
  </el-row>
  <el-row>
    <el-col>
      <main class="LayoutDefault__main" style="padding-top:10px">
        <slot />
      </main>
    </el-col>
  </el-row>
  <el-divider />
  <el-row>
    <el-col align="center">
      <el-row style="margin-top:20px;width:60%">
        <el-col :sm="12" :lg="6">
          <el-result title="OMAGGIO" sub-title="Ricevi uno snack in omaggio su ogni ordine superiore a 50€">
            <template #icon>
              <el-icon style="width:40px">
                <Loading />
              </el-icon>
            </template>
          </el-result>
        </el-col>
        <el-col :sm="12" :lg="6">
          <el-result title="BISOGNO D'AIUTO?" sub-title="nvia un'email ad info@manshiguang.it"> <template #icon>
              <el-icon style="width:40px">
                <Help />
              </el-icon>
            </template>
          </el-result>
        </el-col>
        <el-col :sm="12" :lg="6">
          <el-result title="SPEDIZIONE GRATUITA"
            sub-title="Spedizioni veloci per tutta Italia. Gratuita a partire da 89€* di spesa">
            > <template #icon>
              <el-icon style="width:40px">
                <Discount />
              </el-icon>
            </template>
          </el-result>
        </el-col>
        <el-col :sm="12" :lg="6">
          <el-result title="PAGAMENTI SICURI" sub-title="Tantissimi metodi di pagamento disponibili e sicuri al 100%">
            > <template #icon>
              <el-icon style="width:40px">
                <Ship />
              </el-icon>
            </template>
          </el-result>
        </el-col>
      </el-row>
    </el-col>
  </el-row>

  <el-divider />
  <el-row>
    <el-col :span="4"></el-col>
    <el-col :span="16">
      <el-row>
        <el-col :span="6">
          <el-card class="box-card noborder" header="INFORMAZIONI" shadow="never">
         
            <div>
              <el-link href="/#/disclaimer">Ingredienti Disclaimer</el-link>
            </div>
            <div>
              <el-link href="/static/refund.pdf" target="_blank">Resi e Rimborsi</el-link>
            </div> <div>
              <el-link href="/static/delivery fee.pdf" target="_blank">Spese di Spedizioni</el-link>
            </div> 
          </el-card>

        </el-col>
        <el-col :span="6">
          <el-card class="box-card noborder" header="LINKS" shadow="never" style="width:80%">
            <div>
              <el-link href="/#/contactus">Contattaci</el-link>
            </div>
          </el-card>
        </el-col>
         <el-col :span="6" v-if="false">
          <el-card class="box-card noborder" header="TERMINI" shadow="never" style="width:80%">
              <div>
              <el-link href="/static/privacy.pdf" target="_blank">Informativa sulla Privacy</el-link>
            </div><div>
              <el-link href="/static/terms.pdf" target="_blank">Termini e Condizioni</el-link>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="4"></el-col>
  </el-row>

  <el-drawer v-model="showShoppingCart" :with-header="false">
    <el-icon size="20">
      <ShoppingCart />
    </el-icon><span class="cart-count">{{ cart.carts.length }} articolo</span>
    <el-divider />
    <el-empty description="oops..." v-if="cart.carts.length == 0" />
    <el-row v-for="(item, index) in cart.carts" :key="index" gutter="10">
      <el-col :span="3">
        <el-image :src="item.cover" class="cart-cover">
        </el-image>
      </el-col>
      <el-col :span="17">
        <p class="cart-name">{{ item.spuName }}</p>
        <p class="cart-name">×{{ item.num }}</p>
        <p class="cart-name">
          <Stepper :size="'default'" :data="item" class="stepper" />
        </p>
      </el-col>
      <el-col :span="3" class="cart-price"><span class="cart-name">{{ item.price }}€</span></el-col>
    </el-row>
    <el-row gutter="10" v-if="cart.carts.length > 0">
      <el-col :span="24" class="totalAmount">
        <el-button class="confirm-order" type="danger" round @click="go(200)">Confermare({{ cart.totalAmount }}€)
        </el-button>
      </el-col>
    </el-row>
  </el-drawer>
  <el-drawer v-model="showProfile" :with-header="false">
    <el-form :model="user" label-width="100px" class="profileForm">
      <el-form-item label="nome">
        <el-input v-model="user.name" />
      </el-form-item>
      <el-form-item label="età">
        <el-select v-model="user.age" placeholder="per favore seleziona la tua età">
          <el-option v-for="o in 100" :label="o" :value="o" :key="o" />
        </el-select>
      </el-form-item>
      <el-form-item label="genere">
        <el-radio-group v-model="user.gender">
          <el-radio label="0">maschio</el-radio>
          <el-radio label="1">femmina</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveProfile">salva</el-button>
        <el-button @click="showProfile = fasle">cancella</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>

  <el-drawer v-model="showLogin" :with-header="true" title="Login" size="20%">
    <el-form label-position="top" label-width="100px" :model="loginInfo" style="max-width: 460px">
      <el-form-item label="E-mail">
        <el-input v-model="loginInfo.email" />
      </el-form-item>
      <el-form-item label="Password">
        <el-input v-model="loginInfo.password" type="password" />
      </el-form-item>
      <el-form-item>
        <el-button type="danger" style="width:100%;height:50px" @click="login">LOGIN</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="Default" style="width:100%;height:50px" @click="showLogin = false; showReg = true">Nuovo
          cliente
          ?Crea un account</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>

  <el-drawer v-model="showReg" :with-header="true" title="Crea un account" size="20%">
    <el-form label-position="top" label-width="100px" :model="loginInfo" style="max-width: 460px">
      <el-form-item label="e-mail">
        <el-input v-model="loginInfo.email" type="email">
        </el-input>
      </el-form-item>
      <el-form-item label="codice di verifica">
        <el-input v-model="loginInfo.code">
          <template #append>
            <el-button @click="getCode" :type="warning" :disabled="getCodeBtnDisabled"
              style="background-color: #67c23a;border-radius: 0;color:#fff;height:39px">{{ getCodeBtnText }}</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="password">
        <el-input v-model="loginInfo.password" type="password" />
      </el-form-item>
      <el-form-item label="confermo password">
        <el-input v-model="loginInfo.password1" type="password" />
      </el-form-item>
      <el-form-item>
        <el-button type="danger" style="width:100%;height:50px" :disabled="disabled" @click="reg">CREA ACCOUNT
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="Default" style="width:100%;height:50px" @click="showReg = false; showLogin = true">Hai già un
          account ?Login</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
  <el-backtop :right="100" :bottom="100" />
</template>

<script>
import Stepper from '@/components/Steppers.vue';

export default {
  name: 'LayoutDefault',
  data() {
    return {
      user: null,
      showShoppingCart: false,
      showProfile: false,
      showLogin: false,
      showReg: false,
      isLogin: false,
      disabled: true,
      getCodeBtnText: "ottenere",
      getCodeBtnDisabled: false,
      cart: {
        carts: []
      }, loginInfo: {
        email: '',
        password: '',
        password1: '',
        code: ''
      }
    };
  },
  created() {
    if (this.$categoies.length == 0) {
      this.$httpClient({
        url: "/api/category/list",
        data: {
          shopId: 4,
          type: "children"
        }
      }, (data) => {
        data.forEach((item) => {
          Array.prototype.push.apply(this.$categoies, item.sons);

        });

      }, (res) => { }, true);
    }
    var token = this.$getData("token");
    this.isLogin = (token != null && token != '');
    if (this.isLogin)
      this.getUser();
  }, methods: {
    login() {
      this.$httpClient({
        url: "/api/main/loginNew",
        data: {
          phone: this.loginInfo.email,
          password: this.loginInfo.password,
          code:"111111"
        }
      }, (data) => {
        this.$setData("token", data.token);
        this.$setData("secretKey", data.secretKey);
        this.isLogin = true;
        this.showLogin = false;
        this.$showMsg("Ben tornato");
        this.loginInfo={};
      }, (res) => {
        this.$showMsg("Email o password errati.", "warning");
      }, true);
    }, reg() {
      if (!this.checkMail(this.loginInfo.email))
        return;
      if (this.loginInfo.password != this.loginInfo.password1) {
        this.$showMsg("Le due password non sono uguali.", "warning");
        return;
      }
      this.$httpClient({
        url: "/api/main/loginNew",
        data: {
          phone: this.loginInfo.email,
          code: this.loginInfo.code,
          password: this.loginInfo.password
        }
      }, (data) => {
        this.$setData("token", data.token);
        this.$setData("secretKey", data.secretKey);
        this.isLogin = true;
        this.showReg = false;
        this.loginInfo={};
        this.$showMsg("Registrazione avvenuta");
      }, (res) => {
        console.log("---->", res)
        this.$showMsg("Email o password errati.", "warning");
      }, true);

    },
    checkMail(mail) {
      if (mail == null || mail == undefined || mail == '') {
        this.$showMsg("per favore inserisci la tua email", "warning");
        return false;
      }
      var svgg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      var str = mail;
      //要验证的内容
      if (!svgg.test(str)) {
        this.$showMsg("Il formato dell'e-mail non è corretto", "warning");
        return false;
      }
      return true;
    }
    , getCode() {
      if (!this.checkMail(this.loginInfo.email)) {
        return;
      }
      this.disabled = true;
      this.$httpClient({
        url: "/api/main/getPhoneCode",
        data: {
          phone: this.loginInfo.email
        }
      }, (data) => {
        this.$showMsg("Il codice di verifica è stato inviato, controlla la tua email");
        this.disabled = false;
        var i = 60;
        var waitGetCode = setInterval(() => {
          i -= 1;
          this.getCodeBtnText = "" + i + "";
          this.getCodeBtnDisabled = true;
          if (i == 0) {
            this.getCodeBtnText = "ottenere";
            this.getCodeBtnDisabled = false;
            clearInterval(waitGetCode);
          }
        }, 1000);
      }, (res) => {
        this.disabled = false;
      }, true);
    },
    getUser() {
      this.$httpClient({
        url: "/api/user/getInfo",
        data: {}
      }, (data) => {
        this.user = data;
      }, (res) => { }, true);
    },
    go(idx) {
      switch (parseInt(idx)) {
        case 1:
          this.$router.push({ path: "/" });
          break;
        case 2:
          this.$router.push({
            path: "/list", query: {
              type: "1",
            }
          });
          break;
        case 3:
          this.$router.push({
            path: "/list", query: {
              type: "2",
            }
          });
          break;
        case 4:
          this.$router.push({
            path: "/list", query: {
              type: "3",
            }
          });
          break;
        case 5:
          this.$router.push({
            path: "/category"
          });
          break;
        case 6:
          this.$router.push({
            path: "/about"
          });
          break;
        case 9:
          this.showShoppingCart = true;
          this.getCart();
          break;
        case 100:
          this.$router.push({
            path: "/my"
          });
          break;
        case 101:
          this.showProfile = true;
          break;
        case 102:
          this.$router.push({
            path: "/address"
          });
          break;
        case 106:
          this.$setData("token", "");
          this.isLogin = false;
          break;
        case 104:
          this.showLogin = false;
          this.showReg = true;
          break;
        case 105:
          this.showLogin = true;
          this.showReg = false;
          break;
        case 200:
          this.showShoppingCart = false;
          this.$router.push({
            path: "/cart"
          });
          break;

      }
    }, saveProfile() {
      const newuser = this.user;
      this.$httpClient({
        url: "api/user/update",
        data: newuser
      }, (data) => {
        this.showProfile = false;
        this.$showMsg('esecuzione riuscita');
      }, (res) => { }, true);
    }, goCategory(e, category) {
      this.$router.push({
        path: "/category", query: {
          categoryId: category.categoryId,
          categoryName: category.categoryName
        }
      });
    }, getCart() {

      this.$httpClient({
        url: "api/cart/listWithShop",
        data: {
          shop_id: 4
        }
      }, (data) => {
        if (data.total == 0)
          return;
        this.cart = data.list[0];

      }, (res) => { }, true);
    }
  }, components: {
    Stepper
  }
};
</script>

<style>
.affix-container {
  text-align: center;
  height: 400px;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
}

.flex-grow {
  flex-grow: 1;
}

.logo {
  width: 48px;
  height: 48px;
  border-radius: 5px;
}

.top-menu {
  justify-content: center;
  padding-top: 10px;
}

.el-menu-item.is-active {
  background-color: #fff !important;
  border-bottom: none !important;
}

.el-submenu__title:hover,
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #fff !important;
}

.el-image {
  display: block !important;
}

.noborder {
  width: 80%;
  border: none
}

.profileForm {
  margin-top: 50px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px
}

.cart-count {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  padding-top: -5px;
}

.cart-cover {
  border-radius: 5px;
}

.cart-name {
  font-size: 12px;
  font-weight: 200;
  color: #333;
}

.cart-price {
  text-align: left;
}

.stepper {
  zoom: 60%
}

.totalAmount {
  text-align: right;
  font-weight: 800;

}

.confirm-order {
  padding: 10px 30px 0 0;
  color: #fff
}
.noborder{
  line-height: 30px;
  font-weight: 500;
}
</style>